import React, { Component } from "react";
import PropTypes from "prop-types";
import Layout from "../components/layout";
import SEO from "../components/seo";
import Helmet from "react-helmet";
import config from '../utils/siteConfig'

const BlogPost = ({ data, pageContext }) => {
  const { title, slug,content } = data.contentfulBlogPost;

  const postNode = data.contentfulPage
  
  return (
   <Layout>
    <div>
      <SEO title={`${title} - ${config.siteTitle}`} pagePath={slug} postNode={postNode} postSEO/>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{`${title} - ${config.siteTitle}`}</title>
      </Helmet>
      <div className="grid content container blogPost">
        <div
          dangerouslySetInnerHTML={{ __html: content.childMarkdownRemark.html }}
        />
      </div>
    </div>
   </Layout>
  );
};

export default BlogPost;

export const query = graphql`
  query blogPostQuery($slug: String!) {
    contentfulBlogPost(slug: { eq: $slug }) {
      title
      slug
      content {
        childMarkdownRemark {
          html
        }
      }
    }
  }
`;
