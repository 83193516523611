import React, { Component } from "react";
import Link from "gatsby-link";
import {graphql } from 'gatsby'
import Image from "./image";

export default ({ data }) => (
    <div>
         <nav className="navbar" role="navigation" aria-label="main navigation">
        <div className="navbar-brand">
        
          <a className="navbar-item" href="/">
            <Image/>
          </a>

          <a
            role="button"
            className="navbar-burger burger"
            aria-label="menu"
            aria-expanded="false"
            data-target="navbarBasicExample"
          >
            <span aria-hidden="true" />
            <span aria-hidden="true" />
            <span aria-hidden="true" />
          </a>
        </div>

        <div id="navbarBasicExample" className="navbar-menu">
          <div className="navbar-end">
            <Link to="affiliate-disclouser" className="navbar-item">Affiliate Disclouser</Link>
            <Link to="privacy-policy" className="navbar-item">Privacy Policy</Link>
            <Link to="terms-of-services" className="navbar-item">Terms Of Services</Link>
          </div>
        </div>
      </nav>
    </div>
  )

   


export const pageQuery = graphql`
  query {
    file(relativePath: { eq: "src/images/logo.png" }) {
      childImageSharp {
        fixed(width: 125, height: 125) {
            ...GatsbyImageSharpFixed
          }
      }
    }
  }
`


